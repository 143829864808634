
import LoadingSpinner from '@/components/loading_spinner'
import { as_json } from '@/lib/helpers'

export default {
  components: { LoadingSpinner },

  computed: {
    options: -> @$store.getters['checkout/shipping_options']
    option_count: -> @options.length
    manual_delivery: -> 'MANUAL_DELIVERY' in @options
    deliver: -> 'PICUP' in @options
    collect: -> 'COLLECT' in @options
    deliver_only: -> @option_count == 1 and @deliver
    collect_only: -> @option_count == 1 and @collect
    loading: -> @$store.state.checkout.loading || @init_loading
    checkout_supplier: -> @$store.getters['checkout/supplier']
  }

  data: -> {
    requires_shipping: false
    init_loading: true
    autopicked: false
  }

  methods: {
    choose_delivery: ->
      @$store.commit('checkout/set_requires_shipping', true)
      @$store.commit('checkout/set_shipping_provider', 'PICUP')
      if @autopicked
        @$router.replace('/checkout/shipping_address')
      else
        @$router.push('/checkout/shipping_address')

    choose_manual_delivery: ->
      @$store.commit('checkout/set_requires_shipping', true)
      @$store.commit('checkout/set_shipping_provider', 'MANUAL_DELIVERY')
      if @autopicked
        @$router.replace('/checkout/shipping_address')
      else
        @$router.push('/checkout/shipping_address')

    choose_collection: ->
      @$store.commit('checkout/set_requires_shipping', false)
      if @autopicked
        @$router.replace('/checkout/confirmation')
      else
        @$router.push('/checkout/confirmation')

    back_to_compare: ->
      @$router.replace('/basket/compare')
  }

  created: ->
    @autopicked = true if @collect_only || @deliver_only
    return @choose_collection() if @collect_only
    return @choose_delivery() if @deliver_only
    @init_loading = false
    @$store.commit('layout/page_nav', {
      title: @$t('checkout_dispatch_header'),
      back_target: '/basket'
    })
}
